import { render, staticRenderFns } from "./BrandMediaListItem.vue?vue&type=template&id=525d3714&scoped=true&"
import script from "./BrandMediaListItem.vue?vue&type=script&lang=js&"
export * from "./BrandMediaListItem.vue?vue&type=script&lang=js&"
import style0 from "./BrandMediaListItem.vue?vue&type=style&index=0&id=525d3714&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "525d3714",
  null
  
)

export default component.exports