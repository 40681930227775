<template>
  <div class="single-list-item" @click="click">
    <div class="single-list-top">
      <img :src="mediaSource.thumb" alt="" />
    </div>
    <div class="single-list-bottom">
      <VueHeadline level="4" weightLevel="3" class="single-item-text"> {{ text }}</VueHeadline>
      <div v-if="showPercentage" class="progressbar-wrapper">
        <div class="progressbar">
          <div class="completed-part" :style="{ width: `${percentage * 2.25}px` }"></div>
        </div>
        <VueText sizeLevel="4" weightLevel="2"> % {{ `${percentage.toFixed()}` }} </VueText>
      </div>
    </div>
  </div>
</template>

<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';

export default {
  name: 'BrandMediaListItem',
  components: { VueHeadline, VueText },
  props: {
    text: {
      type: String,
      default: '',
    },
    percentage: {
      type: Number,
      default: 0,
    },
    mediaSource: {
      type: Object,
    },
    showPercentage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    click(e, param) {
      this.$emit('handleRoute', e, param);
    },
  },
};
</script>

<style scoped lang="scss">
.single-list-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  & > div {
    flex: 1;
  }
}
.media {
  width: 175px;
  height: 100px;
}
.single-list-top {
  img {
    width: 100%;
  }
}
.single-list-bottom {
  background-color: palette-color-level('white', '100');
  padding: palette-space-level(20);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .single-item-text {
    line-height: 1.33;
    min-height: palette-space-level(50);
  }

  .progressbar-wrapper {
    display: flex;
    align-items: center;
    padding-top: palette-space-level(30);

    .progressbar {
      position: relative;
      width: 225px;
      height: 6px;
      background-color: #dddddd;
      border-radius: palette-radius-level('8');
      margin-right: palette-space-level('10');

      .completed-part {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background: green;
        border-radius: palette-radius-level('8');
      }
    }
  }
}
</style>
