<template>
  <div class="content-layout-fixer" id="divUsersManualHomeWrapper">
    <div class="video-wrapper">
      <BrandVimeoPlayer
        v-if="mediaData"
        :data="mediaData"
        :id="mediaData.videoId"
      ></BrandVimeoPlayer>
    </div>
    <div class="video-list-holder">
      <div class="video-list-wrapper">
        <div v-for="item in mediaItems" :key="item.id">
          <BrandMediaListItem
            :text="item.title"
            :mediaSource="item"
            :showPercentage="false"
            @handleRoute="handleVideoRouting(item)"
          ></BrandMediaListItem>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import BrandMediaListItem from '@/components/brand/BrandMediaListItem/BrandMediaListItem.vue';
import vimeoUtils from '@/mixins/vimeoUtils.js';

export default {
  name: 'UserManualNavigation',
  components: {
    BrandVimeoPlayer,
    BrandMediaListItem,
  },
  mixins: [vimeoUtils],
  data() {
    return {
      mediaData: null,
      mediaItems: [
        {
          embedVideo: '//player.vimeo.com/video/434990079',
          title: 'Puanları İndirime Dönüştürmek',
          videoId: 9564,
        },
        {
          embedVideo: '//player.vimeo.com/video/434989893',
          title: 'Oyna Kazan',
          videoId: 9565,
        },
        {
          embedVideo: '//player.vimeo.com/video/434990245',
          title: 'Performans Takibi',
          videoId: 9566,
        },
        {
          embedVideo: '//player.vimeo.com/video/434988720',
          title: 'Profil Sayfası',
          videoId: 9567,
        },
        {
          embedVideo: '//player.vimeo.com/video/434990419',
          title: 'Yaşam & Akademi',
          videoId: 9569,
        },
        {
          embedVideo: '//player.vimeo.com/video/468493401',
          title: 'Ön Sipariş nasıl verilir?',
          videoId: 9910,
        },
        {
          embedVideo: '//player.vimeo.com/video/434987200',
          title: "PMAktif'i Mobil Cihazlara Kısa Yol Olarak Eklemek",
          videoId: 9562,
        },
        {
          embedVideo: '//player.vimeo.com/video/434990191',
          title: 'Ön Sipariş',
          videoId: 9563,
        },
      ],
    };
  },
  methods: {
    handleVideoRouting(item) {
      this.mediaData = null;
      this.$route.meta.breadcrumb[2].name = item.title;
      setTimeout(() => {
        this.mediaData = item;
        window.scrollTo(0, 0);
      }, 200);
    },
  },
  created() {
    this.mediaData = this.mediaItems[0];
    this.$route.meta.breadcrumb[2].name = this.mediaItems[0].title;
    this.mediaItems = this.getVideoThumbs(this.mediaItems);
  },
};
</script>
<style lang="scss" scoped>
.video-list-holder {
  border-top: 1px solid palette-color-level('grey', '20');
  margin: palette-space-level(20) 0;
  padding-left: palette-space-level(20);

  .video-list-wrapper {
    margin-top: palette-space-level(20);
    display: grid;
    grid-template-columns: repeat(3, 321px);
    grid-gap: palette-space-level(20);
  }

  .video-list-wrapper > div {
    border: 1px solid palette-color-level('grey', '20');
  }
}
</style>
