import axios from 'axios';

export default {
  data() {
    return {};
  },
  methods: {
    getVideoIdFromEmbedVideo(embedVideo) {
      return embedVideo.substring(embedVideo.lastIndexOf('/') + 1);
    },

    getVideosList(elem, videoId) {
      return axios.get(`https://vimeo.com/api/oembed.json?url=${elem.embedVideo}&id=${videoId}`);
    },

    getVideoThumbs(array) {
      let embedThumbs = [];
      array.forEach(elem => {
        const videoId = this.getVideoIdFromEmbedVideo(elem.embedVideo);
        this.getVideosList(elem, videoId).then(res => {
          if (res.data) {
            embedThumbs.push({
              ...elem,
              thumb: res.data.thumbnail_url_with_play_button,
              thumbWithoutPlayButton: res.data.thumbnail_url,
            });
          }
        });
      });
      return embedThumbs;
    },

    async getVideoThumbsAsync(array) {
      let embedThumbs = [];

      for (const elem of array) {
        const videoId = this.getVideoIdFromEmbedVideo(elem.embedVideo);

        try {
          const res = await this.getVideosList(elem, videoId);

          if (res.data) {
            embedThumbs.push({
              ...elem,
              thumb: res.data.thumbnail_url_with_play_button,
              thumbWithoutPlayButton: res.data.thumbnail_url,
            });
          }
        } catch (error) {
          console.error('Error fetching video thumbnail:', error);
        }
      }

      return embedThumbs;
    },
  },
};
